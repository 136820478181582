import React, { useCallback, useEffect, useState } from "react";
import { gapi } from "gapi-script";
import googleDivePng from "../../Assets/images/icons8-google-drive.svg";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";

var SCOPE = "https://www.googleapis.com/auth/drive.file";
// var SCOPE = ['https://www.googleapis.com/auth/spreadsheets', 'https://www.googleapis.com/auth/drive']
var DISCOVERY_URL =
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest";
const CLIENT_ID = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
const API_KEY = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;

const GoogleDriveUpload = ({ linkData, linkFileName }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState({
    loading: false,
    button: "",
  });

  const createFile = useCallback(
    async (file) => {
      try {
        const fileBlob = await fetch(file).then((e) => e.blob());

        const fileMetadata = {
          name: linkFileName,
        };
        const form = new FormData();
        form.append(
          "metadata",
          new Blob([JSON.stringify(fileMetadata)], { type: "application/json" })
        );
        form.append("file", fileBlob);
        let header = {
          method: "POST",
          headers: new Headers({
            Authorization: "Bearer " + gapi.auth.getToken().access_token,
          }),
          body: form,
        };

        fetch(
          "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
          header
        )
          .then((res) => res.json())
          .then((res) => {
            setShowLoader(false);
            if (res.error && res.error.code === 403) {
              handleAuthClick();
            } else {
              toast.success("Item Uploaded successfully");
            }
          });
      } catch (e) {
        toast.error(e);
      }
    },
    [linkFileName]
  );

  const updateSigninStatus = useCallback(
    async (isSignedIn) => {
      if (isSignedIn) {
        setShowLoader(true);
        if (linkData) {
          createFile(linkData);
        }
      } else {
        handleAuthClick();
      }
    },
    [createFile, linkData]
  );

  const initClient = useCallback(() => {
    try {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          scope: SCOPE,
          plugin_name: "streamy",
          discoveryDocs: [DISCOVERY_URL],
        })
        .then(() => {
          gapi.auth2
            .getAuthInstance()
            .isSignedIn.listen(() => console.log("Auth"));
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        });
    } catch (e) {
      toast.error(e);
    }
  }, [updateSigninStatus]);

  useEffect(() => {
    if (!!linkData && !!downloadLoading.button) {
      gapi.load("client:auth2", initClient);
      setDownloadLoading({ loading: false, button: "" });
      setShowLoader(true);
    }
  }, [linkData, downloadLoading, initClient]);

  const handleAuthClick = (event) => {
    gapi.auth2.getAuthInstance().signIn();
  };
  return (
    <div
      onClick={() => setDownloadLoading({ loading: true, button: "gdrive" })}
      className="source-container"
    >
      <div
        className={`drive-btn position-relative pointer px-3  ${
          showLoader || downloadLoading.loading
            ? "d-flex align-items-center"
            : ""
        }`}
      >
        {showLoader || downloadLoading.loading ? (
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            color="primary"
          />
        ) : (
          <img src={googleDivePng} className="btn-logo" alt="Google Drive" />
        )}

        <div className="btn-text"> Save to Google Drive</div>
      </div>
    </div>
  );
};

export default GoogleDriveUpload;
