import React from "react";

const Footer = () => {
  return (
    <footer className="GrowCloud_Footer pt-3 pb-3">
      <div className="cloud-container">
        <div className="row">
          <div className="col-md-12">
            <div className="text-sm-center d-none d-sm-block footerText">
              This site and all content is © {new Date().getFullYear()} Stuff You Can Use, Inc
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
