export const previewTypes = [
    'mp4',
    'folder',
    'pdf',
    'jpg',
    'jpeg',
    'mp3',
    'png',
    'webm',
    'svg',
    'mpeg',
    'wav',
]

export const downloadAbleFiles = [
    // 'mp4',
    // 'pdf',
    // 'jpg',
    // 'jpeg',
    // 'mp3',
    // 'png',
    // 'webm',
    // 'svg',
    // 'psd',
    // 'mpeg',
    // 'wav',
    // 'gif',
    // 'txt',
    // 'csv'
    'zip'
]

export const hiddenFiles = [
    "DS_Store"
]
