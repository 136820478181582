import React from "react";

const PageNotFound = () => {
  return (
    <div className="page-content d-flex justify-content-center align-items-center">
      <div className="body">
        <div className="cloud-container pt-4 pb-4">
          <div className="row">
            <div className="card main-card card-company-table pt-4 pb-4 mb-0">
              <div className="h-100 data-center">
                <div className="col-xl-8 col-md-10 col-lg-10 col-12 share-link-main p-5 rounded-3">
                  <i className="fas fa-search share-link-icon data-center"></i>
                  <h3 className="data-center">File not found</h3>
                  <h4 className="data-center">
                    The document could not be found on the server. Maybe the
                    share was deleted or has expired?
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
