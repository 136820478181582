import React, { useRef } from "react";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const FilePreview = ({ data, setIndex, index, handleClose }) => {
  const imgRef = useRef();
  // const [style, setStyle] = useState({ display: "block" });
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  document.onkeydown = function (event) {
    switch (event.keyCode) {
      case 37:
        if (index - 1 === -1) {
          return;
        } else {
          setIndex(index - 1);
        }
        break;
      case 38:
        if (
          data[index].extension === "mp4" ||
          data[index].extension === "mov"
        ) {
          return false;
        } else {
          if (imgRef.current.clientWidth === 2500) return false;
          else {
            imgRef.current.style.width =
              imgRef.current.clientWidth + 100 + "px";
          }
        }
        break;
      case 39:
        if (index + 1 === data.length) {
          return;
        } else {
          setIndex(index + 1);
        }
        break;
      case 40:
        if (
          data[index].extension === "mp4" ||
          data[index].extension === "mov"
        ) {
          return false;
        } else {
          if (imgRef.current.clientWidth === 100) return false;
          else {
            imgRef.current.style.width =
              imgRef.current.clientWidth - 100 + "px";
          }
        }
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div className="preview-1">
        <div
          className="gallery-close d-flex justify-content-between w-100"
          title="Close"
        >
          <div className="text-light ms-4">{data[index]?.name}</div>
          <button onClick={handleClose} className="close-btn">
            <i className="fa-solid fa-xmark" id="close"></i>
          </button>
        </div>
        <div className="preview-div">
          {(() => {
            if (
              data[index]?.extension === "jpg" ||
              data[index]?.extension === "png" ||
              data[index]?.extension === "svg" ||
              data[index]?.extension === "jpeg"
            ) {
              return (
                <img
                  // ref={imgRef}
                  className="w-100 overflow-auto imagePreview"
                  src={data[index]?.path}
                  alt="..."
                />
              );
            } else if (
              data[index]?.extension === "mp4" ||
              data[index]?.extension === "webm" ||
              data[index]?.extension === "avi"
            ) {
              return (
                <video
                  controls
                  autoPlay
                  className="w-100"
                  extension={data[index]?.extension}
                  src={data[index]?.path}
                ></video>
              );
            } else if (data[index]?.extension === "pdf") {
              return (
                // <iframe
                //   title="display PDF"
                //   src={data[index].path}
                //   className="pdf-embed"
                // ></iframe>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.js">
                  <div
                    // className="pdfViewer"
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.3)",
                      height: "90vh",
                      width: "90vw",
                      // marginTop: '60px'
                    }}
                  >
                    <Viewer
                      fileUrl={data[index].path}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                    {/* <iframe title="display PDF" src={data[index].path} className="pdf-embed"></iframe> */}
                  </div>
                </Worker>
              );
            } else if (
              data[index]?.extension === "mp3" ||
              data[index]?.extension === "mpeg" ||
              data[index]?.extension === "wav"
            ) {
              return (
                <audio controls autoPlay>
                  <source src={data[index]?.path}></source>
                </audio>
              );
            } else {
              return (
                <div className="text-light text-center">
                  {data[index]?.extension} is not supported for preview
                </div>
              );
            }
          })()}
          <div className="file-handler-preview"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilePreview;
