import React, { useEffect } from "react";
import growLogo from "../../Assets/images/logo.png";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPageCountResourceList } from "../../Redux/Home/actions";
import { Helmet } from "react-helmet";
import DropBoxUpload from "./DropBoxUpload";
import GoogleDriveUpload from "./GoogleDriveUpload";
import { toast } from "react-toastify";
import Download from "../../Assets/images/download";

const Header = ({ setParentId, setIsSelectedFolder, superParentId }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isValidLink, parentFolderName } = useSelector((state) => state.home);

  useEffect(() => {
    setParentId(parseInt(location.pathname.split("/")[1]));
  }, [location.pathname, setParentId]);

  const handleOnClick = () => {
    setParentId(superParentId);
    dispatch(setPageCountResourceList(1));
    setIsSelectedFolder([]);
  };

  const downloadAll = async () => {
    try {
      var a = document.createElement("a");
      a.href = `${process.env.REACT_APP_API_CLOUD}api/v1/downloadzip/${
        location.search.split("=")[1]?.split("/")[0]
      }`;
      document.body.appendChild(a);
      a.click();
      a.download = true;
      document.body.removeChild(a);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  return (
    <div className="header">
      <Helmet>
        <script
          src="https://apis.google.com/js/platform.js"
          async
          defer
        ></script>

        <script
          type="text/javascript"
          src="https://www.dropbox.com/static/api/2/dropins.js"
          id="dropboxjs"
          data-app-key={process.env.REACT_APP_DROPBOX_APP_KEY}
        ></script>
      </Helmet>
      <div className="cloud-container head-nav">
        <div className="header-left">
          <div
            className="nextcloud position-relative cursor-pointer"
            onClick={handleOnClick}
          >
            <img src={growLogo} alt="Grow Logo" className="logo" />
          </div>
        </div>
        <div className="header-right">
          {/* web view */}
          {isValidLink && (
            <div className="header-right-web flex-wrap">
              <div className={`source-container `}>
                <div
                  className="drive-btn position-relative me-2 pointer px-3"
                  onClick={() => {
                    downloadAll();
                  }}
                >
                  <Download className="download-icon" />
                  <span className="btn-text ms-1">Download All</span>
                </div>
              </div>
              {!!parentFolderName && (
                <DropBoxUpload
                  linkData={`${
                    process.env.REACT_APP_API_CLOUD
                  }api/v1/downloadzip/${
                    location.search.split("=")[1]?.split("/")[0]
                  }`}
                  linkFileName={`${parentFolderName}.zip`}
                />
              )}
              {!!parentFolderName && (
                <GoogleDriveUpload
                  linkData={`${
                    process.env.REACT_APP_API_CLOUD
                  }api/v1/downloadzip/${
                    location.search.split("=")[1]?.split("/")[0]
                  }`}
                  linkFileName={`${parentFolderName}.zip`}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
