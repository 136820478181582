import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import {
  setListAllResourceDataError,
  setPageCountResourceList,
} from "../../Redux/Home/actions";
const BreadCrumb = ({
  parentFolderName,
  superParentId,
  parentId,
  link,
  setParentId,
  handleSearch,
  isSelectedFolder,
  handleListingView,
  handleItemsLength,
  parentShareLink,
  isFirstPage,
}) => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.home);
  //UseStates
  const [isListView, setIsListView] = useState(false);
  //handle List View and Grid View
  const handleListView = () => {
    setIsListView(!isListView);
    handleListingView(!isListView);
  };



  return (
    <nav className=" row">
      <div className="align-self-end col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
        <Link
          to={link}
          className="breadCrumbHome "
          onClick={() => {
            if (superParentId !== parentId) {
              setParentId(superParentId);
              handleItemsLength(-1);
              dispatch(setListAllResourceDataError());
              dispatch(setPageCountResourceList(1));
            }
          }}
        >
          <i
            className={
              isSelectedFolder.length === 0
                ? "ri-home-2-fill breadCrumbSelectedItem"
                : "ri-home-2-fill"
            }
          ></i>
        </Link>
        <span> {` > `}</span>
        {
          !!parentFolderName &&
          <Link
            to={link}
            className="breadCrumbHome "
            onClick={() => {
              if (superParentId !== parentId) {
                setParentId(superParentId);
                handleItemsLength(-1);
                dispatch(setListAllResourceDataError());
                dispatch(setPageCountResourceList(1));
              }
            }}
          >
            <span className={
              isSelectedFolder.length === 0
              && "breadCrumbSelectedItem"
            }>{unescape(parentFolderName) + ` > `}</span>
          </Link>
        }
        <span>
          {isSelectedFolder.map((v, i) => (
            <span
              key={i}
              className={
                isSelectedFolder[isSelectedFolder.length - 1].title ===
                  v.title &&
                  isSelectedFolder[isSelectedFolder.length - 1].id === v.id
                  ? "breadCrumbItem breadCrumbSelectedItem"
                  : "breadCrumbItem"
              }
              onClick={() => {
                if (parentId !== v.id) {
                  setParentId(v.id);
                  handleItemsLength(i);
                  dispatch(setPageCountResourceList(1));
                  dispatch(setListAllResourceDataError());
                  dispatch(setPageCountResourceList(1));
                }
              }}
            >
              {v.title.toUpperCase() +
                (i < isSelectedFolder.length ? " > " : "")}
            </span>
          ))}
          {loading && (
            <i
              className="fa fa-spinner fa-spin breadcrumbLoadingIcon"

            ></i>
          )}
        </span>
      </div>
      <div className="main-left-breadcrumb col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
        <div className={`search cursor-not-allowed `}>
          <i
            className={`fas fa-search search-icon ${isFirstPage ? "d-none" : "d-block"
              }`}
          ></i>
          <input
            className={`search-field ${isFirstPage ? "d-none" : "d-block"}`}
            placeholder="Search"
            onChange={handleSearch}
          />
        </div>
        <div className="mx-2 fileListViewIcon d-none" onClick={handleListView}>
          {!isListView ? (
            <>
              <i className="ri-layout-grid-fill" id="GridView"></i>
            </>
          ) : (
            <>
              <i className="ri-list-check" id="GridView"></i>
            </>
          )}
          <UncontrolledTooltip placement="bottom" target="GridView">
            {isListView ? "List View" : "Grid View"}
          </UncontrolledTooltip>
        </div>
      </div>
    </nav>
  );
};
export default BreadCrumb;
