import { axiosCloud } from "../../Services/api";

import {
  GET_LIST_RESOURCES_DATA,
  GET_LIST_RESOURCES_DATA_SUCCESS,
  GET_LIST_RESOURCES_DATA_ERROR,
  SET_PAGE_RESOURCE_LIST,
  VALIDATE_PAGE_RESOURCE_LIST,
  VALIDATE_PAGE_RESOURCE_LIST_SUCCESS,
  VALIDATE_PAGE_RESOURCE_LIST_ERROR,
  SET_IS_VALID_LINK,
  SET_PARENT_FOLDER_NAME,
} from "./actionTypes";

import {
  GET_RESOURCES_DATA,
  VALIDATE_RESOURCES_DATA,
} from "../../Constants/urls";
import { toast } from "react-toastify";

//GET_LIST_RESOURCES_DATA
export const listCloud = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(setListAllResourceData(true));
      const res = await axiosCloud.post(GET_RESOURCES_DATA, {
        page_no: payload.page_no,
        page_record: payload.page_record,
        parent_id: payload.parent_id,
        search: payload.search,
        sortOrder: payload.sortOrder,
      });
      cb(res.data);
      if (!!res) {
        dispatch(
          setListAllResourceDataSuccess({
            data: res?.data?.data?.rows || [],
            count: parseInt(res?.data?.data?.count || 0),
            parent_id: payload?.parent_id,
            isPagination: payload?.isPagination,
            isValidateShareLink: false,
          })
        );
      }
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch(setListAllResourceDataError(err.message));
    }
  };
};

export const validateShareLink = (payload, cb = () => {}) => {
  return async (dispatch) => {
    try {
      dispatch(validateShareLinkResourceData(true));
      const res = await axiosCloud.post(VALIDATE_RESOURCES_DATA, payload);
      cb(res.data);
      if (res.data?.data?.data) {
        if (res.data.data.data.resource.type === 1) {
          dispatch(
            validateShareLinkResourceDataSuccess({
              data: res.data.data.data.resource.type,
              shareLink: res.data.data.data.resource_id,
            })
          );
          if (res.data.data.data.resource) {
            dispatch(
              setListAllResourceDataSuccess({
                data: [res.data.data.data.resource],
                count: 1,
                isValidateShareLink: true,
              })
            );
          }
        } else {
          dispatch(
            validateShareLinkResourceDataSuccess({
              data: res.data.data.data.resource.type,
              shareLink: res.data.data.data.resource_id,
            })
          );
        }
      }
    } catch (err) {
      toast.error(err);
      dispatch(validateShareLinkResourceDataError(err));
    }
  };
};

export const handleIsValidLink = (data) => {
  return async (dispatch) => {
    dispatch(setIsValidLink(data));
  };
};

//GET_LIST_RESOURCES_DATA
export const setListAllResourceData = (data) => {
  return {
    type: GET_LIST_RESOURCES_DATA,
    payload: data,
  };
};
export const setListAllResourceDataSuccess = (data) => ({
  type: GET_LIST_RESOURCES_DATA_SUCCESS,
  payload: data,
});

export const setListAllResourceDataError = (data) => ({
  type: GET_LIST_RESOURCES_DATA_ERROR,
  payload: data,
});

//VALIDATE_LIST_RESOURCES_DATA
export const validateShareLinkResourceData = (data) => {
  return {
    type: VALIDATE_PAGE_RESOURCE_LIST,
    payload: data,
  };
};
export const validateShareLinkResourceDataSuccess = (data) => ({
  type: VALIDATE_PAGE_RESOURCE_LIST_SUCCESS,
  payload: data,
});
export const validateShareLinkResourceDataError = (data) => ({
  type: VALIDATE_PAGE_RESOURCE_LIST_ERROR,
  payload: data,
});

// Set Page Count By Data List
export const setPageCountResourceList = (data) => {
  return {
    type: SET_PAGE_RESOURCE_LIST,
    payload: data,
  };
};

export const setIsValidLink = (data) => {
  return {
    type: SET_IS_VALID_LINK,
    payload: data,
  };
};

export const setParentFolderName = (data) => {
  return {
    type: SET_PARENT_FOLDER_NAME,
    payload: data,
  };
};
