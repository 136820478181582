import React from "react";
import {
  Routes,
  // Navigate,
  Route,
} from "react-router-dom";
import routes from "./routes.js";
// import { getToken } from "./LocalStorage";
import Main from "./Main.js";
import HomeContainer from "../Containers/Home";
import PageNotFound from "../Components/Common/PageNotFound.js";

const Full = (props) => {
  return (
    // <Main {...props}>
    <Routes>
      <Route
        path={routes.home}
        exact
        checkCondition={false}
        element={
          <Main {...props}>
            <HomeContainer {...props} />
          </Main>
        }
      />
      {/* <Redirect from="/" to={routes.home} /> */}
      <Route path="*" exact element={<PageNotFound />} />
    </Routes>
    // </Main>
  );
};

export default Full;
