//Images
import jpg from "../Assets/images/icons/jpg.svg";
import jpeg from "../Assets/images/icons/jpg.svg";
import svg from "../Assets/images/icons/jpg.svg";
import psd from "../Assets/images/icons/jpg.svg";
import png from "../Assets/images/icons/jpg.svg";
import wbmp from "../Assets/images/icons/jpg.svg";
import ithmb from "../Assets/images/icons/jpg.svg";
import tiff from "../Assets/images/icons/jpg.svg";
import cr2 from "../Assets/images/icons/jpg.svg";
import raw from "../Assets/images/icons/jpg.svg";
import gif from "../Assets/images/icons/jpg.svg";
import webp from "../Assets/images/icons/jpg.svg";
import ico from "../Assets/images/icons/jpg.svg";
import nef from "../Assets/images/icons/jpg.svg";
import tif from "../Assets/images/icons/jpg.svg";
import eps from "../Assets/images/icons/jpg.svg";

import pdf from "../Assets/images/icons/pdf.svg";

import zip from "../Assets/images/icons/zip.svg";

import folder from "../Assets/images/icons/folder.svg";

import doc from "../Assets/images/icons/doc.svg";
import docs from "../Assets/images/icons/doc.svg";
import docx from "../Assets/images/icons/doc.svg";

import xls from "../Assets/images/icons/xls.svg";
import xlsx from "../Assets/images/icons/xls.svg";

import txt from "../Assets/images/icons/txt.svg";
import md from "../Assets/images/icons/txt.svg";

import ppt from "../Assets/images/icons/ppt.svg";
import pptx from "../Assets/images/icons/ppt.svg";

import csv from "../Assets/images/icons/xls.svg";


import mpeg from "../Assets/images/icons/mp3.svg";
import mp3 from "../Assets/images/icons/mp3.svg";
import wav from "../Assets/images/icons/mp3.svg";

import webm from "../Assets/images/icons/mp4.svg";
import mp4 from "../Assets/images/icons/mp4.svg";
import dvi from "../Assets/images/icons/mp4.svg";
import m4a from "../Assets/images/icons/mp4.svg";
import mov from "../Assets/images/icons/mp4.svg";



const Extension = {
  mp4,
  zip,
  folder,
  doc,
  docs,
  docx,
  pdf,
  xls,
  jpg,
  jpeg,
  txt,
  ppt,
  mp3,
  png,
  csv,
  webm,
  xlsx,
  svg,
  psd,
  pptx,
  mpeg,
  gif,
  wav,
  wbmp,
  ithmb,
  tiff,
  cr2,
  raw,
  webp,
  ico,
  nef,
  tif,
  dvi,
  m4a,
  eps,
  mov,
  md
};

export default Extension;
