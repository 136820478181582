import React from 'react'
import Download from '../../Assets/images/download'
import Checkbox from '../../Components/Common/Checkbox'
import loaderIcon from '../../Assets/images/loaderIcon.gif'
// import moment from 'moment'
import { UncontrolledTooltip } from 'reactstrap'
import { toast } from 'react-toastify'
import { otherItemsDownloadOnMessage } from '../../Constants/Messages'

const Table = ({

  types,
  nameSort,
  dataLoading,
  setIsCheckedAll,
  isCheckedAll,
  handleSelectAll,
  formatBytes,
  selectedId,
  handleCheckOrUncheck,
  previewViewerHandler,
  downloadImage,
  isNoDataFound,
  isDownload,
  handleSortByName,
  multiDownload,
  isFirstPage,
  isMultiDownload,
  home,
  hiddenFiles,
}) => {
  const { listCloudData, loading, pageNoResource, listCloudDataCount } = home
  //UseStates

  return (
    // <div className="table-parent-div">
    <table className="table ">
      <thead>
        <tr>
          <th className="selection-cell-header" data-row-selection="true">
            <div
              className="position-relative tableSelectAllBox"
              id="SelectAll"
            >
              <input
                disabled={isNoDataFound}
                type="checkbox"
                id="selectAll"
                className="chb chb-3"
                checked={isCheckedAll}
                onChange={() => {
                  if (!isMultiDownload && !isDownload) {
                    setIsCheckedAll(!isCheckedAll)
                    handleSelectAll(!isCheckedAll)
                  } else {
                    toast(otherItemsDownloadOnMessage)
                  }
                }}
              />
              <label htmlFor={'selectAll'}></label>
              <UncontrolledTooltip placement="bottom" target="SelectAll">
                {isNoDataFound
                  ? 'No data found'
                  : selectedId.length > 0
                    ? 'Unselect Items'
                    : 'Select All'}
              </UncontrolledTooltip>
            </div>
          </th>

          <th scope="col" className="sortable">
            <div className="nameColumn">
              <div className="mx-2">
                {selectedId.length > 0 || isMultiDownload ? (
                  <div className="p-0 m-0 d-flex position-relative">
                    <span>{selectedId.length} Items selected</span>
                    <div
                      className={`${isDownload ? 'd-none' : 'd-block'
                        }`}
                      id="downloadAll"
                      onClick={() => multiDownload('')}
                    >
                      {isMultiDownload ? (
                        <span className="mx-5">
                          <i className="fa fa-spinner fa-spin"></i>
                        </span>
                      ) : (
                        <Download className="mx-5" />
                      )}
                    </div>

                    <UncontrolledTooltip
                      placement="bottom"
                      target="downloadAll"
                    >
                      {isMultiDownload ? 'Downloading..' : 'Download'}
                    </UncontrolledTooltip>
                  </div>
                ) : (
                  'Name'
                )}
              </div>

              {selectedId.length <= 0 && !isFirstPage && (
                <div className="cursor-pointer">
                  <i
                    // isNameSort && nameSort === 'ASC'
                    className={
                      nameSort === 'ASC'
                        ? 'fa-solid fa-sort-up nameSort cursor-pointer'
                        : 'fa-solid fa-sort-up cursor-pointer'
                    }
                    onClick={() => handleSortByName()}
                  ></i>
                  <i
                    className={
                      nameSort === 'DESC'
                        ? 'fa-solid fa-sort-down downArrowSort nameSort cursor-pointer'
                        : 'fa-solid fa-sort-down downArrowSort cursor-pointer'
                    }
                    onClick={() => handleSortByName()}
                  ></i>
                </div>
              )}
            </div>
          </th>
          <th
            scope="col"
            className="size text-center modified"
          >
            {selectedId.length > 0 ? '' : 'Size'}
          </th>
          {/* <th scope="col" className="modified" >
            {selectedId.length > 0 ? '' : 'Modified'}
          </th> */}
          <th className="text-center opacity-0">download</th>
        </tr>
      </thead>
      <tbody>
        {listCloudDataCount === 0 && !dataLoading ? (
          <tr>
            <td colSpan={10}>
              <center>
                <h6 className="my-1">No Data Found</h6>
              </center>
            </td>
          </tr>
        ) : (dataLoading && pageNoResource === 1) || (loading && pageNoResource === 1) ? (
          <tr className="loaderImage">
            <td className="text-center" colSpan={6}>
              <div>
                <span className="">
                  <img src={loaderIcon} alt="Loading..." />
                </span>
              </div>
            </td>
          </tr>
        ) : (
          listCloudData?.map((element, index) => {
            return (
              !hiddenFiles.includes(element?.extension) && (
                <tr key={index}>
                  <td className="selection-cell">
                    <Checkbox
                      id={element?.id}
                      selectedId={selectedId}
                      path={element?.path}
                      title={element?.title}
                      type={element?.type}
                      handleCheckOrUncheck={handleCheckOrUncheck}
                    />
                  </td>
                  <td
                    className="cursor-pointer"
                    onClick={(e) => previewViewerHandler(e, element)}
                  >
                    <div className='d-flex'>
                      <div
                        className="folderName symbol symbol-45px me-2 d-flex"
                        id={'icon' + element?.extension}
                      >
                        <span className="symbol-label">
                          <img
                            className="align-self-center icon-size-list"
                            src={
                              types[
                                element.extension ? element.extension : 'folder'
                              ] === undefined
                                ? types['folder']
                                : types[
                                element.extension ? element.extension : 'folder'
                                ]
                            }
                            alt="Icon"
                          />
                        </span>
                        {
                          <UncontrolledTooltip
                            placement="bottom"
                            target={'icon' + element.extension}
                          >
                            {element.extension === '' || element.extension === null
                              ? 'Folder'
                              : element.extension}
                          </UncontrolledTooltip>
                        }
                      </div>
                      <div className="data-center">
                        <p
                          className="text-truncate mb-0 text-capitalize tableTitle"
                        >
                          {element.title}
                        </p>
                      </div>
                    </div>
                  </td>

                  <td className="text-center">
                    <span>
                      {element.size === '' || parseInt(element.size) === 0 || element.type === 0 ? '-' : formatBytes(element.size)}
                    </span>
                  </td>

                  {/* <td>{moment(element?.updated_datetime).fromNow()}</td> */}
                  <td
                    className='downloadColumn'
                    onClick={() => {
                      isDownload || isMultiDownload
                        ? toast(otherItemsDownloadOnMessage)
                        : downloadImage(
                          element
                        )
                    }
                    }
                    colSpan="5"
                  >
                    <div className='d-flex justify-content-center'>
                      {
                        isDownload ? (
                          <div className=''>
                            <div>
                              <i
                                className="fa fa-spinner fa-spin tableDownloadLoading"
                              ></i>
                            </div>

                          </div>
                        ) : (
                          <div
                            className="symbol undefined"
                            id={'download' + element.id}
                          >

                            <span className="symbol-label symbol-dark-bg">
                              {
                                // downloadData.isDownload && downloadData.id === element.id ?
                                //   <Spinner className='spinner-border-sm' /> :
                                <Download className="" />

                              }
                            </span>
                            {
                              <UncontrolledTooltip
                                placement="bottom"
                                target={'download' + element.id}
                              >
                                download
                              </UncontrolledTooltip>
                            }
                          </div>
                        )}
                    </div>
                  </td>
                </tr>
              )
            );
          })
        )}
      </tbody>
    </table>
    // </div>
  )
}

export default Table
