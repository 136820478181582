import React from "react";

const Checkbox = ({
  id,
  selectedId,
  handleCheckOrUncheck,
  path,
  title,
  type,
}) => {
  return (
    <div className="position-relative">
      <input
        type="checkbox"
        className="chb chb-3"
        id={id}
        checked={selectedId.includes(id)}
        onChange={() => {
          handleCheckOrUncheck(id, path, title, type);
        }}
      />
      <label htmlFor={id}></label>
    </div>
  );
};

export default Checkbox;
