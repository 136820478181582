import {
  GET_LIST_RESOURCES_DATA,
  GET_LIST_RESOURCES_DATA_SUCCESS,
  GET_LIST_RESOURCES_DATA_ERROR,
  SET_PAGE_RESOURCE_LIST,
  VALIDATE_PAGE_RESOURCE_LIST,
  VALIDATE_PAGE_RESOURCE_LIST_SUCCESS,
  VALIDATE_PAGE_RESOURCE_LIST_ERROR,
  SET_IS_VALID_LINK,
  SET_PARENT_FOLDER_NAME,

} from "./actionTypes";
import produce from "immer";

const initialState = {
  loading: false,
  listCloudData: [],
  singleCloudData: 0,
  listCloudDataCount: 0,
  pageNoResource: 1,
  shareLinkId: [],
  publicLink: "",
  isValidLink: true,
  parentFolderName: null
};

const reducer = produce((state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    //GET_LIST_RESOURCES_DATA
    case GET_LIST_RESOURCES_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_LIST_RESOURCES_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        listCloudData: !!!state.listCloudData ? [] : payload.isPagination
          ? [...state.listCloudData, ...payload.data]
          : payload.isValidateShareLink
            ? payload.data
            : payload.data,
        listCloudDataCount: payload.count,
      };
    case GET_LIST_RESOURCES_DATA_ERROR:
      return {
        ...state,
        loading: false,
        listCloudData: [],
      };

    case VALIDATE_PAGE_RESOURCE_LIST:
      return {
        ...state,
        loading: true,
      };
    case VALIDATE_PAGE_RESOURCE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        shareLinkId: payload.shareLink,
        singleCloudData: payload.data
      };
    case VALIDATE_PAGE_RESOURCE_LIST_ERROR:
      return {
        ...state,
        loading: false,
      };

    // Set Page Count By Data List
    case SET_PAGE_RESOURCE_LIST:
      return {
        ...state,
        pageNoResource: payload,
      };
    case SET_IS_VALID_LINK:
      return {
        ...state,
        isValidLink: payload
      }
    case SET_PARENT_FOLDER_NAME:
      return {
        ...state,
        parentFolderName: payload
      }
    default:
      return state;
  }
});

export default reducer;
