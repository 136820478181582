import { render } from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import "./Assets/sass/app.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import store from "./Redux";

const rootElement = document.getElementById("root");
render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);
