import load from "load-script";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
// import dropBoxPng from "../../Assets/images/icons8-dropbox.svg";

const DROPBOX_SDK_URL = "https://www.dropbox.com/static/api/2/dropins.js";
const SCRIPT_ID = "dropboxjs";

const DropBoxUpload = ({ linkData, linkFileName }) => {
  const [change, setChange] = useState(true);

  const success = useCallback(() => {
    toast.success("Item Uploaded successfully");
    setChange(!change);
  }, [change]);

  var options = useMemo(() => {
    return {
      appKey: process.env.REACT_APP_DROPBOX_APP_KEY,
      disabled: false,
      files: [
        {
          url: linkData,
          filename: linkFileName,
        },
      ],
      success: function () {
        success();
      },
      progress: function (progress) {},
      cancel: function () {},
      error: function (errorMessage) {
        toast.error(errorMessage);
      },
    };
  }, [linkData, linkFileName, success]);

  useEffect(() => {
    load(
      DROPBOX_SDK_URL,
      {
        attrs: {
          id: SCRIPT_ID,
          "data-app-key": options.appKey,
        },
      },
      (err, script) => {
        if (err) toast.error("script not added");
        else {
          var button = window.Dropbox.createSaveButton(options);
          let btnData = document.getElementById("saver");
          if (btnData) {
            btnData.innerHTML = "";
            btnData
              .appendChild(button)
              .setAttribute("style", "background:none;border:none;");
          }
        }
      }
    );
  }, [change, options]);

  return (
    <div
      className={`drive-btn position-relative me-2 text-decoration-none pointer px-3`}
    >
      <div id="saver" className="drpbx btn-text ms-1"></div>
    </div>
  );
};

export default DropBoxUpload;
