//GET_LIST_RESOURCES_DATA
export const GET_LIST_RESOURCES_DATA = "GET_LIST_RESOURCES_DATA";
export const GET_LIST_RESOURCES_DATA_SUCCESS =
  "GET_LIST_RESOURCES_DATA_SUCCESS";
export const GET_LIST_RESOURCES_DATA_ERROR = "GET_LIST_RESOURCES_DATA_ERROR";

// Set Page Count By Data List
export const SET_PAGE_RESOURCE_LIST = "SET_PAGE_RESOURCE_LIST";

export const VALIDATE_PAGE_RESOURCE_LIST = "VALIDATE_PAGE_RESOURCE_LIST";
export const VALIDATE_PAGE_RESOURCE_LIST_SUCCESS =
  "VALIDATE_PAGE_RESOURCE_LIST_SUCCESS";
export const VALIDATE_PAGE_RESOURCE_LIST_ERROR =
  "VALIDATE_PAGE_RESOURCE_LIST_ERROR";
export const SET_IS_VALID_LINK = "SET_IS_VALID_LINK"

export const SET_PARENT_FOLDER_NAME = "SET_PARENT_FOLDER_NAME"

