import React from "react";
import Contain from "../Components/Contain";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

const Main = (props) => {
  return (
    <div>
      <Header {...props} />
      <Contain>{props?.children}</Contain>
      <Footer />
    </div>
  );
};

export default Main;
