import axios from "axios";

const API_URL_CLOUD = process.env.REACT_APP_BASE_URL + "api/v1/";
const API_URL_CLOUD_MAIN = process.env.REACT_APP_API_CLOUD + "api/v1/";
// const API_URL_CLOUD = "http://192.168.1.175:8081/api/v1/";

const axiosCloud = axios.create({
  baseURL: API_URL_CLOUD,
});
const axiosCloudMain = axios.create({
  baseURL: API_URL_CLOUD_MAIN,
});

export { axiosCloud, axiosCloudMain };
